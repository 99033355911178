site-footer {
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  width: 100%;
  align-items: center;
}
site-footer a {
  color: black;
}
site-footer [name="frame"] {
  align-items: center;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #ccc;
  display: flex;
  min-height: 30px;
  justify-content: stretch;
  width: 100%;
}
site-footer [name="frame"] [name="footer-text"] {
  flex: 1 1;
  text-align: center;
  padding: 10px;
}
site-footer [name="frame"] [name="choose-language"] {
  align-items: center;
  display: flex;
  flex: 0 0 184px;
  justify-content: space-around;
  padding-right: 6px;
}
site-footer [name="frame"] [name="choose-language"] img {
  width: 32px;
  height: 18px;
}
site-footer [name="frame"] [name="choose-language"] input {
  width: 18px;
  height: 16px;
}
