shipping-carrier {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0 0 5px 0;
  transition: max-height 0.6s;
  max-height: 29px;
}
shipping-carrier [name=hide] {
  display: none;
}
shipping-carrier [name=expand] {
  display: flex;
}
shipping-carrier[open] {
  max-height: 600px;
}
shipping-carrier[open] [name=expand] {
  display: none;
}
shipping-carrier[open] [name=hide] {
  display: flex;
}
shipping-carrier [name=overview] {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4px 0 6px;
  flex: 0 0 28px;
}
shipping-carrier [name=overview] > div {
  display: flex;
}
shipping-carrier [name=overview] > div svg {
  width: 14px;
  margin-right: 3px;
}
shipping-carrier [name=overview] button {
  border: none;
  font-size: 12px;
  background: #e3e3e3;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 64px;
  height: 20px;
}
shipping-carrier [name=overview] button svg {
  height: 20px;
}
shipping-carrier [name=options] {
  display: flex;
  flex-direction: column;
  flex: 0 0 0px;
  width: 100%;
  padding: 0 5px 0 5px;
}
shipping-carrier [name=options] label {
  align-items: center;
  border-radius: 3px;
  border: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  padding: 6px 0;
  width: 100%;
  cursor: pointer;
}
shipping-carrier [name=options] label[checked] {
  background-color: #dfdfdf;
}
shipping-carrier [name=options] label input {
  margin: 7px;
}
shipping-carrier [name=options] label > div div:first-of-type {
  font-weight: bold;
}
