impersonate-selector {
  background-color: black;
  z-index: 2;
  margin-top: -10px;
}
@media screen and (max-width: 800px) {
  impersonate-selector {
    position: fixed !important;
    left: 0;
    top: 0;
    width: 100%;
  }
}
impersonate-selector > div {
  padding: 10px 10px 0 10px;
}
impersonate-selector input {
  border-radius: 2px;
  border: none;
  padding: 4px;
  width: 400px;
  max-width: 100%;
  margin: 10px 0;
  font-size: 14px;
}
impersonate-selector ul {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 1.5;
  color: white;
  text-align: left;
  font-size: 14px;
}
impersonate-selector ul li {
  display: flex;
  padding: 3px 10px;
  background-color: black;
}
impersonate-selector ul li[blocked] {
  background-color: #3F0000;
  color: #BF9393;
}
impersonate-selector ul li div:first-of-type {
  flex: 0 0 60%;
}
impersonate-selector ul li[selected] {
  transform: scale(1.1);
  transition: all 0.3s;
}
