._29D0csI-CMAQsp3zlFWZjR {
  align-items: flex-end;
  border-radius: 4px;
  display: flex;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 400;
  height: 33px;
  justify-content: center;
  width: 200px;
  width: 100%;
  margin-top: 10px;
}
._29D0csI-CMAQsp3zlFWZjR svg {
  height: 23px;
  width: auto;
  margin-right: 8px;
}
