wait-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.2);
}
wait-spinner > div {
  width: 290px;
  height: 100px;
  background: white;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  font-family: sans-serif;
  border: 1px solid #999999;
  box-shadow: 0 0 13px -1px #37373766;
}
wait-spinner > div > img {
  margin-right: 10px;
}
