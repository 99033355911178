quick-order {
  background-color: #f3f3f3;
  border-radius: 4px;
  border: 1px solid #aaa;
  box-sizing: border-box;
  display: block;
  font-family: sans-serif;
  margin: 0 10px 12px !important;
  padding: 10px 20px 20px;
  width: calc(100% - 22px);
}
quick-order header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
quick-order header div {
  font-weight: 600;
  font-size: 13px;
  padding: 0 0 10px;
  color: #6b6b6b;
  line-height: 1.8;
}
quick-order header div:last-of-type {
  font-weight: 400;
  font-size: 12px;
}
quick-order > div {
  display: flex;
  align-items: flex-end;
}
quick-order > div > * + * {
  margin-left: 10px;
}
quick-order > div > * {
  flex: 1;
}
quick-order > div label {
  display: flex;
  flex-direction: column;
  color: #6d6d6d;
}
quick-order > div input {
  border: 1px solid #ccc;
  padding: 4px 8px;
  margin-top: 5px;
  border-radius: 3px;
}
quick-order > div input[error] {
  border-color: red;
}
quick-order > div button {
  max-width: 133px;
  height: 25px;
  border-radius: 4px !important;
  font-size: 12px;
  border: none !important;
  cursor: pointer;
}
quick-order > div button:disabled {
  opacity: 0.5;
  cursor: default;
}
