energy-label {
  position: relative;
}
energy-label a {
  text-decoration: none;
}
energy-label > * {
  float: right;
  height: 100%;
  width: 165px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
energy-label > * > * {
  margin: 0 2px;
}
energy-label .reveal {
  opacity: 0;
  width: 300px;
  position: absolute;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  margin-top: 200px;
  margin-left: 460px;
  transition: opacity 0.4s;
  background-color: white;
  border-radius: 3px;
  z-index: 1000;
  pointer-events: none;
}
energy-label .reveal img {
  width: 100%;
}
energy-label .reveal img:first-of-type {
  border-top: 2px solid #0f98d7;
  border-bottom: 2px solid #0f98d7;
}
energy-label .reveal .alt-image {
  position: absolute;
  z-index: -1;
}
energy-label:hover .reveal {
  opacity: 1;
  pointer-events: all;
}
energy-label .snowgrip,
energy-label .icegrip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 15px;
  font-size: 10px;
  margin-right: 4px;
  margin-bottom: -6px;
}
energy-label .snowgrip svg,
energy-label .icegrip svg {
  height: 24px;
  margin-bottom: 4px;
}
energy-label .euLabelLarge {
  position: absolute;
  z-index: 999999;
  display: none;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
  width: 200px;
  height: 300px;
  overflow: hidden;
}
energy-label .euClassification {
  overflow: hidden;
  float: left;
  width: 98px;
  margin: 0 0 0 2px;
  font-size: 12px;
  color: #2a2a2a;
  font-weight: bold;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
energy-label .fuel {
  float: left;
  width: 14px;
  height: 17px;
  margin: 0 0 0 0;
  padding: 29px 0 0 12px;
  background-image: url(/dist/6cd7254b3eb7099211c90171adebb435.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  font-size: 11px;
  line-height: 15px;
}
energy-label .wet {
  float: left;
  width: 14px;
  height: 17px;
  margin: 0 0 0 0;
  padding: 29px 0 0 12px;
  background-image: url(/dist/6cd7254b3eb7099211c90171adebb435.png);
  background-repeat: no-repeat;
  background-position: -50px 0;
  font-size: 11px;
  line-height: 15px;
}
energy-label .noise {
  float: left;
  width: 44px;
  height: 46px;
  margin: 0 0 0 0;
  background-image: url(/dist/6cd7254b3eb7099211c90171adebb435.png);
  background-repeat: no-repeat;
}
energy-label .noise .number {
  float: left;
  width: 18px;
  text-align: right;
  margin: 29px 0 0 9px;
  font-size: 11px;
  line-height: 15px;
}
energy-label .noise .db {
  float: left;
  font-size: 10px;
  margin: 31px 0 0 1px;
}
energy-label .noise1 {
  background-position: -102px 0;
}
energy-label .noise2 {
  background-position: -102px -50px;
}
energy-label .noise3 {
  background-position: -102px -100px;
}
