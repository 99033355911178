image-ad {
  width: 483px;
  overflow: hidden;
  display: block;
  border: 6px solid black;
  padding: 2px;
  border-radius: 11px;
  height: 346px;
  position: relative;
  box-sizing: border-box;
}
image-ad img {
  width: 100%;
  height: auto;
}
image-ad a {
  display: block;
  height: 100%;
  width: 100%;
}
image-ad div {
  position: absolute;
  height: calc(100% - 4px);
  width: calc(100% - 4px);
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  border-radius: 2px;
  transition: opacity 2s ease;
}
image-ad button {
  position: absolute;
  top: 50%;
  background: none !important;
  border: none !important;
  cursor: pointer;
  transition: none;
  display: none;
  height: 50px;
  margin-top: -25px;
  padding: 0 !important;
  align-items: center;
  justify-content: center;
}
image-ad button:hover {
  transform: scale(1.2);
}
image-ad button svg {
  width: 60px;
  height: 60px;
  fill: white;
  filter: drop-shadow(0px 0px 40px black) drop-shadow(0px 0px 40px black) drop-shadow(0px 0px 40px black);
}
image-ad button:first-of-type {
  left: 20px;
}
image-ad button:last-of-type {
  right: 20px;
}
image-ad:hover button {
  display: flex;
}
.image-ad-frame {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 14px 0;
  font-size: 0;
}
