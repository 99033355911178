order-control {
  font-family: Arial;
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  border: 1px solid #ccc;
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
  padding: 2px;
  max-height: 50px;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}
order-control[open] {
  max-height: 1000px;
  transition: max-height 0.5s ease-in-out;
}
order-control .children-2 .order-line > div {
  min-height: 160px;
}
order-control .children-3 .order-line > div {
  min-height: 65px;
}
order-control .order-head {
  display: flex;
  flex-direction: column;
}
order-control .order-head .order-text {
  height: 44px;
  min-height: 44px;
  background: var(--gradient);
  color: white;
  align-items: center;
  padding: 0 15px;
  font-size: 15px;
  border-radius: 2px;
  cursor: pointer;
}
order-control .order-head .order-text span {
  font-weight: bold;
  font-size: 15px;
}
order-control .order-head .order-text .description {
  flex: 0;
  margin-right: 10px;
}
order-control .order-head .order-text button {
  flex: 0 0 90px;
  margin-left: auto;
  cursor: pointer;
  padding: 4px;
  border-radius: 3px;
  border: none;
  font-size: 12px;
}
order-control .order-head > div {
  width: 100%;
  display: flex;
}
order-control .order-head .delivery-message {
  background-color: #eee;
  padding: 12px 6px;
  margin-bottom: 2px;
  font-size: 14px;
}
order-control .order-detail {
  display: flex;
}
order-control .order-detail .order-lines {
  flex: 1;
}
order-control .order-detail .order-total {
  background-color: #eee;
  display: flex;
  flex-direction: column;
  flex: 0 0 150px;
  margin-left: 2px;
  padding: 12px;
}
order-control .order-detail .order-total .total-excluding-vat,
order-control .order-detail .order-total .vat,
order-control .order-detail .order-total .total-including-vat {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}
order-control .order-detail .order-total .total-including-vat {
  font-weight: bold;
}
order-control .order-detail .order-total .total-including-vat money-amount {
  font-size: 16px;
}
order-control .order-detail .order-total h2 {
  color: #121212;
  font-size: 18px;
  margin-block-start: 0;
  width: 100%;
  text-align: center;
}
order-control .order-detail .order-total button {
  align-items: center;
  background: var(--highlight-gradient);
  border-radius: 3px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: auto;
}
order-control .order-detail .order-total button svg {
  width: 15px;
  height: 28px;
  margin-right: 7px;
}
order-control header {
  background-color: #d0d0d0;
  font-weight: bold;
}
order-control header > * {
  padding: 4px;
}
order-control header .price,
order-control header .total {
  text-align: center;
}
order-control .order-line:nth-child(even) {
  background-color: #eee;
}
order-control .order-line:nth-child(odd) {
  background-color: #f9f9f9;
}
order-control .order-line .description {
  font-size: 14px;
}
order-control .order-line .price,
order-control .order-line .total {
  text-align: right;
  border-left: 1px solid #d8d8d8;
}
order-control .order-line .count {
  padding-left: 6px;
}
order-control .order-line,
order-control header {
  display: flex;
  font-size: 12px;
}
order-control .order-line > *,
order-control header > * {
  padding: 4px;
}
order-control .order-line .count,
order-control header .count {
  flex: 0 0 40px;
}
order-control .order-line .count div:after,
order-control header .count div:after {
  content: "×";
  margin-left: 2px;
  color: #888888;
}
order-control .order-line .item-id,
order-control header .item-id {
  flex: 0 0 50px;
}
order-control .order-line .image,
order-control header .image {
  flex: 0 0 150px;
}
order-control .order-line .image img,
order-control header .image img {
  max-width: 120px;
  max-height: 120px;
}
order-control .order-line .description,
order-control header .description {
  flex: 1 1 300px;
  color: #444;
  font-weight: 700;
}
order-control .order-line .description div,
order-control header .description div {
  font-weight: 400;
  margin-top: 3px;
  font-size: 12px;
}
order-control .order-line .price,
order-control header .price {
  flex: 0 0 58px;
}
order-control .order-line .total,
order-control header .total {
  flex: 0 0 58px;
}
