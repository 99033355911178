[item-type="Accessory"] .si_size {
  opacity: 0;
}
[item-type="Accessory"] * {
  cursor: default !important;
}
[item-type="Accessory"] buy-button {
  height: 25px;
  display: block;
}
[item-type="Accessory"] buy-button button {
  cursor: pointer !important;
}
buy-button button {
  background: var(--seconday-background);
  border-radius: 4px;
  border: none;
  color: black;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  width: 100%;
  height: 100%;
}
buy-button button:hover {
  background: var(--seconday-background-hover);
}
buy-button button:disabled {
  opacity: 0.5;
}
