tyre-added {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
tyre-added * {
  box-sizing: border-box;
}
tyre-added > section {
  background-color: white;
  border-radius: 5px;
  border: 1px solid #585858;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25), 0 0 50px 0px #0000007a;
  max-height: 100%;
  max-width: 100%;
  padding: 2px;
  width: 650px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
tyre-added > section > header {
  background: var(--gradient);
  height: 31px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  width: 100%;
}
tyre-added > section > header h3 {
  font-size: 13px;
  font-weight: bold;
  padding: 0 14px;
  color: white;
  font-family: sans-serif;
}
tyre-added > section > header button {
  background: hsla(0, 0%, 0%, 0.3);
  border-radius: 3px;
  border: 1px inset hsla(0, 0%, 0%, 0.48);
  color: hsla(0, 0%, 100%, 0.78);
  cursor: pointer;
  float: right;
  font-size: 14px;
  font-weight: bold;
  height: 24px;
  line-height: 14px;
  margin: 0 3px;
  width: 36px;
}
tyre-added > section > header button:hover {
  color: white;
}
tyre-added > section > div {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
tyre-added > section > div > * {
  width: 100%;
  margin: 10px 0 0 !important;
}
tyre-added > section > div .buttons {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  box-sizing: border-box;
}
tyre-added > section > div .buttons button {
  background: var(--primary-background);
  border-radius: 4px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  height: 25px !important;
  margin-left: 10px;
  min-width: 133px;
}
tyre-added > section > div .buttons button:hover {
  background: var(--primary-background-hover);
}
tyre-added > section > div .buttons button[type=submit] {
  background: var(--seconday-background);
  color: black;
}
tyre-added > section > div .buttons button[type=submit]:hover {
  background: var(--seconday-background-hover);
}
