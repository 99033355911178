product-display {
  width: 100%;
  display: flex;
  height: 42px;
  align-items: center;
  justify-content: space-between;
  height: 306px;
  flex-direction: column;
  background-color: white;
  font-family: Arial, Helvetica, sans-serif;
  padding: 8px;
  border-right: 1px solid #bababa;
  border-bottom: 1px solid #bababa;
  font-size: 12px;
}
product-display .image {
  flex: 0 0 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}
product-display .image img {
  max-width: 100px;
  max-height: 100px;
}
product-display .item-id {
  font-size: 11px;
  font-weight: bold;
  flex: 0 0 15px;
  margin-top: auto;
}
product-display .price {
  flex: 0 0 28px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
product-display .price > div {
  display: flex;
  flex-direction: column;
  width: fit-content;
}
product-display .price span {
  font-size: 10px;
  display: block;
  font-weight: bold;
}
product-display .item-no {
  flex: 1 1 145px;
}
product-display .description {
  flex: 0 0 80px;
  font-size: 11px;
}
product-display .description b {
  font-size: 12px;
}
product-display accessory-stock {
  flex: 0 0 15px;
}
product-display .buy {
  display: flex;
  align-items: center;
  width: 100%;
}
product-display .buy quantiy-dropdown {
  align-self: flex-start;
}
product-display .buy select {
  height: 24px;
  margin-top: 1px;
}
product-display .buy buy-button {
  margin-left: auto;
  align-self: flex-end;
  height: 25px;
  width: 90px;
}
product-display zoomable-image {
  border: none !important;
}
