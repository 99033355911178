._2y7OS25S46NHWvJYrk_p_f {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  margin: 4px 0;
  min-height: 30px;
  overflow: hidden;
  width: 100%;
  font-family: sans-serif;
}
