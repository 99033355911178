quick-search {
  background-color: black;
  margin-left: calc(-175px) !important;
  margin-top: 0;
  z-index: 10;
  position: absolute;
}
quick-search > div {
  padding: 10px 10px 0 10px;
}
quick-search input {
  border-radius: 2px;
  border: none;
  padding: 4px;
  width: 400px;
  max-width: 100%;
  margin: 10px 0;
  background-color: white;
  font-size: 14px;
}
