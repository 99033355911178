._1RJ_a_SWHrZg4-O8wI-Es8 {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  position: relative;
  width: 100%;
  z-index: 1;
}
._1RJ_a_SWHrZg4-O8wI-Es8 h1 {
  width: 100%;
  text-align: center;
}
._1RJ_a_SWHrZg4-O8wI-Es8 input {
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  width: 100%;
  height: 100%;
}
._1RJ_a_SWHrZg4-O8wI-Es8 > div {
  background-color: white;
  border-radius: 5px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 4px 0;
  height: 42px;
  overflow: hidden;
}
._1RJ_a_SWHrZg4-O8wI-Es8 > div button {
  align-items: center;
  background: var(--gradient);
  color: White;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  height: 100%;
  justify-content: center;
  text-decoration: none;
  border: none;
  padding: 0 20px;
}
