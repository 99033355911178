[name="order-and-delivery"] {
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
}
[name="order-and-delivery"] * {
  box-sizing: border-box;
}
[name="order-and-delivery"] input {
  padding: 2px 4px;
}
[name="order-and-delivery"] .divDeliveryOption {
  width: 100%;
}
[name="order-and-delivery"] .divDeliveryOption .delivery-option-desc {
  width: calc(100% - 33px);
}
[name="order-and-delivery"] [name="title-bar"] {
  width: 100%;
}
[name="order-and-delivery"] [name="address-part"] {
  display: flex;
  width: 50%;
  flex-wrap: wrap;
}
[name="order-and-delivery"] [name="address-part"] [name="delivery-address"] {
  width: 266px;
  height: 252px;
}
[name="order-and-delivery"] [name="address-part"] [name="delivery-address"] td {
  padding: 2px;
}
[name="order-and-delivery"] [name="address-part"] [name="delivery-address"] input[type=text] {
  width: 190px;
}
[name="order-and-delivery"] [name="address-part"] [name="billing-address"] {
  width: 215px;
  height: 124px;
}
[name="order-and-delivery"] [name="address-part"] [name="delivery-date"] {
  width: 215px;
  height: 125px;
  line-height: 1.35;
}
[name="order-and-delivery"] [name="address-part"] .ba_line,
[name="order-and-delivery"] [name="address-part"] .ba_extra {
  padding: 1px;
}
[name="order-and-delivery"] [name="address-part"] .step2-references .inner {
  margin-left: 5px;
}
[name="order-and-delivery"] [name="address-part"] .step2-references .inner input {
  width: 370px;
}
[name="order-and-delivery"] [name="delivery"] {
  width: 50%;
  margin: 0;
  padding: 0 3px;
}
[name="order-and-delivery"] [name="delivery"] > * {
  width: 100%;
}
[name="order-and-delivery"] [name="delivery"] .BasketStep {
  margin: 0 0 3px;
}
[name="order-and-delivery"] [name="delivery"] [name=delivery-type] {
  height: 334px;
  overflow-y: auto;
  overflow-x: hidden;
}
[name="order-and-delivery"] [disabled] h3 {
  opacity: 0.5;
}
[name="order-and-delivery"] [name=delivery-remark] {
  padding: 7px;
  line-height: 1.3;
}
button.top {
  position: absolute;
  right: 4px;
  height: 20px;
  border: none;
  font-size: 12px;
  background: var(--primary-background);
  color: white;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 64px;
}
button.top:hover {
  background: var(--primary-background-hover);
}
button.top:disabled {
  display: none;
}
button.top svg {
  height: 20px;
}
