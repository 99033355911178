:root {
  --eurowheels-color: #a61e1e;
  --primary-background: linear-gradient(to bottom, #767676 0%, #474747 50%, #2d2d2d 100%);
  --primary-background-hover: linear-gradient(to bottom, #d51c05 0%, #b8160a 50%, #860d02 100%);
  --seconday-background: linear-gradient(to bottom, #fbbe52 0%, #ec982f 50%, #cb6707 100%);
  --seconday-background-hover: linear-gradient(to bottom, #f3d08d 0%, #f4a448 50%, #cb6707 100%);
}
prompt-dialog {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: brightness(60%);
}
prompt-dialog > div {
  width: 100%;
  height: fit-content;
  max-width: 450px;
  background-color: white;
  color: black;
  border-radius: 4px;
  overflow: hidden;
}
prompt-dialog > div header {
  background: var(--primary-background);
  color: white;
  height: 40px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  font-weight: bold;
}
prompt-dialog > div .question {
  padding: 15px;
}
prompt-dialog > div .buttons {
  padding: 0 15px 15px;
  display: flex;
}
prompt-dialog > div .buttons button {
  background: var(--primary-background);
  color: White;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  height: 26px;
  cursor: pointer;
  vertical-align: top;
  border-radius: 4px;
  margin-left: 10px;
  border: none;
  flex: 0 0 130px;
}
prompt-dialog > div .buttons button:first-of-type {
  margin-left: auto;
}
prompt-dialog > div .buttons button:hover {
  background: var(--primary-background-hover);
}
prompt-dialog > div .buttons button[primary] {
  background: var(--seconday-background);
  color: black;
}
prompt-dialog > div .buttons button[primary]:hover {
  background: var(--seconday-background-hover);
}
