._1F5tc6HTxp3PcKVRFSoPiv {
  height: auto;
  position: absolute;
  top: 50px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}
._1F5tc6HTxp3PcKVRFSoPiv label {
  padding: 4px 0 8px;
  color: white;
  font-size: 14px;
}
