basket-summary {
  background-color: white;
  overflow: hidden;
  width: 100%;
  z-index: 3;
  display: block;
  line-height: 10px;
  font-family: sans-serif;
}
basket-summary .loader {
  display: flex;
  align-items: center;
  font-size: 15px;
}
basket-summary .loader img {
  margin-right: 8px;
}
basket-summary header {
  font-weight: 400;
  color: black;
}
@media screen and (max-width: 800px) {
  basket-summary {
    position: fixed !important;
    left: 0;
    top: 0;
    width: 100%;
  }
}
basket-summary .set {
  padding: 5px 0;
}
basket-summary [name=item-view] {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
basket-summary .item {
  display: flex;
  color: hsl(0, 0%, 40%);
  padding: 7px 10px;
  font-weight: 300;
  font-size: 14px;
  line-height: 1;
}
basket-summary .item > div:first-of-type {
  flex: 0 0 30px;
}
basket-summary .item span {
  width: 40px;
}
basket-summary .item[new][item-id] {
  color: black;
  font-weight: 600 !important;
}
basket-summary hr {
  width: 100%;
  border-color: hsl(0, 0%, 67%);
}
basket-summary .shipping {
  color: black;
  padding: 5px;
}
basket-summary .price {
  box-sizing: border-box;
  color: black;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  width: 100%;
  margin-top: 20px;
  font-size: 14px;
}
basket-summary .price > div:last-of-type {
  text-align: right;
}
