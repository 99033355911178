my-stock-introduction {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  font-family: Roboto, sans-serif;
}
my-stock-introduction .panel {
  background-color: white;
  border-radius: 6px;
  box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.19);
  min-height: 280px;
  max-height: 100%;
  max-width: 100%;
  width: 600px;
  padding: 0 20px 20px 20px;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}
my-stock-introduction .panel [name=buttons] {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 10px;
}
my-stock-introduction .panel [name=buttons] a {
  text-decoration: none !important;
  background: none !important;
}
my-stock-introduction .panel [name=buttons] button.primary,
my-stock-introduction .panel [name=buttons] button.secondary {
  align-items: center;
  background: var(--gradient);
  border-radius: 5px;
  border: none;
  color: White;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  height: 34px;
  justify-content: center;
  margin: 5px;
  padding: 0 16px;
  text-decoration: none;
}
my-stock-introduction .panel [name=buttons] button.secondary {
  background: none;
  color: black;
}
