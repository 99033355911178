/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('/dist/roboto-v20-latin-300.eot');
  /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'), url('/dist/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/dist/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */ url('/dist/roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */ url('/dist/roboto-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */ url('/dist/roboto-v20-latin-300.svg#Roboto') format('svg');
  /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('/dist/roboto-v20-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'), url('/dist/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/dist/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('/dist/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */ url('/dist/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('/dist/roboto-v20-latin-regular.svg#Roboto') format('svg');
  /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('/dist/roboto-v20-latin-500.eot');
  /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'), url('/dist/roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/dist/roboto-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */ url('/dist/roboto-v20-latin-500.woff') format('woff'), /* Modern Browsers */ url('/dist/roboto-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */ url('/dist/roboto-v20-latin-500.svg#Roboto') format('svg');
  /* Legacy iOS */
}
