accessories-picker {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 3px;
  background: white;
  margin-top: 10px;
}
accessories-picker label,
accessories-picker button {
  margin: 20px 0 20px 20px;
}
accessories-picker label:last-of-type,
accessories-picker button:last-of-type {
  margin-right: 20px;
}
accessories-picker label {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: bold;
  font-family: sans-serif;
}
accessories-picker label > * {
  margin-top: 5px;
}
accessories-picker input,
accessories-picker select,
accessories-picker button {
  font-size: 15px;
  padding: 3px 6px;
  height: 29px;
}
accessories-picker .divider {
  background: #bababa;
  width: 1px;
  height: 65px;
}
accessories-picker button {
  align-items: center;
  background: var(--gradient);
  border-radius: 5px;
  color: White;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  justify-content: center;
  text-decoration: none;
  padding: 0 30px;
  height: 28px;
  align-self: flex-end;
  white-space: nowrap;
}
accessories-picker .filter {
  display: flex;
  align-items: center;
}
accessories-picker > header {
  background: var(--gradient);
  color: white;
  font-weight: bold;
  height: 30px;
  display: flex;
  align-items: center;
  font-family: sans-serif;
  text-indent: 10px;
  font-size: 12px;
}
accessories-picker > div {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: stretch;
}
accessories-picker .tpms {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex: 1 0;
}
accessories-picker .tpms input[type="text"] {
  background-image: url(/img/RegistrationPlateDecor.png);
  background-repeat: no-repeat;
  border: 2px solid red;
  border-radius: 5px;
  height: 28px;
  margin-top: 1px;
  text-indent: 20px;
  width: 100%;
  font-size: 18px;
  font-family: sans-serif;
  max-width: 138px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  outline: none;
}
