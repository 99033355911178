.divSearchCriteriaOuter .expanded {
  height: 250px !important;
}
.divSearchCriteriaOuter .search-criteria-header {
  display: flex;
}
.divSearchCriteriaOuter .search-criteria-header > div:first-of-type {
  margin-top: 2px;
  flex: 0;
}
.divSearchCriteriaOuter select#criteriaManufacturer {
  margin-top: 5px;
}
.divSearchCriteriaOuter header {
  margin: 6px;
  font-size: 11px;
  font-weight: bold;
}
.tyre-tech-details {
  font-size: 10px;
  position: absolute;
  bottom: 5px;
}
.tyre-tech-details a {
  color: #000;
  text-decoration: none;
  padding-left: 6px;
}
.tyre-tech-details a:hover {
  text-decoration: underline;
}
.badge {
  position: absolute;
}
