tpms-buy-dialog {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000045;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
tpms-buy-dialog * {
  box-sizing: border-box;
}
tpms-buy-dialog [name=frame] {
  width: 100%;
  max-width: 470px;
  background-color: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
tpms-buy-dialog [name=frame] header {
  background: var(--primary-background);
  color: white;
  height: 40px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 20px;
}
tpms-buy-dialog [name=frame] header,
tpms-buy-dialog [name=frame] div {
  text-align: center;
  width: 100%;
}
tpms-buy-dialog [name=frame] [name=type-panel] > label,
tpms-buy-dialog [name=frame] [name=buy-panel] > label,
tpms-buy-dialog [name=frame] [name=color-panel] > label {
  font-weight: bold;
  margin-bottom: 3px;
}
tpms-buy-dialog [name=frame] [name=buy-panel],
tpms-buy-dialog [name=frame] [name=color-panel] {
  max-height: 0;
  overflow: hidden;
  padding: 0;
  transition: all 0.5s ease;
  margin-top: 10px;
  align-items: center;
}
tpms-buy-dialog [name=frame] [name=buy-panel][show],
tpms-buy-dialog [name=frame] [name=color-panel][show] {
  max-height: 100px;
}
tpms-buy-dialog [name=frame] [name=buy-panel] {
  margin: 10px 0 20px;
}
tpms-buy-dialog [name=frame] div {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
tpms-buy-dialog [name=frame] div .field {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin: 3px 0;
}
tpms-buy-dialog [name=frame] div .field label {
  margin-left: 3px;
}
tpms-buy-dialog [name=frame] div button {
  cursor: pointer;
  text-transform: uppercase;
  height: 38px;
  width: 160px;
  border: none;
  border-radius: 3px;
  color: white;
  font-weight: bold;
  background: var(--primary-background);
}
tpms-buy-dialog [name=frame] div button:disabled {
  opacity: 0.4;
  pointer-events: none;
}
tpms-buy-dialog [name=frame] div button:hover {
  background: var(--primary-background-hover);
}
tpms-buy-dialog [name=frame] div button[primary] {
  background: var(--seconday-background);
  color: black;
}
tpms-buy-dialog [name=frame] div button[primary]:hover {
  background: var(--seconday-background-hover);
}
