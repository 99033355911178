basket-viewer {
  background-color: black;
  overflow: hidden;
  width: 300px;
  z-index: 3;
}
@media screen and (max-width: 800px) {
  basket-viewer {
    position: fixed !important;
    left: 0;
    top: 0;
    width: 100%;
  }
}
basket-viewer button {
  width: 100%;
  background-color: var(--eurowheels-color);
  color: white;
  font-size: 12px;
  border-radius: 2px;
  border: none;
  margin-top: 6px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
basket-viewer button:hover {
  transform: scale(1.1);
}
basket-viewer .set {
  padding: 5px 0;
  background-color: hsl(0, 0%, 67%);
}
basket-viewer .item {
  display: flex;
  color: white;
  padding: 7px 10px;
}
basket-viewer .item > div:first-of-type {
  flex: 0 0 30px;
}
basket-viewer hr {
  width: 100%;
  border-color: hsl(0, 0%, 67%);
}
basket-viewer .shipping {
  color: white;
  padding: 5px;
}
basket-viewer .price {
  box-sizing: border-box;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  width: 100%;
}
basket-viewer .price > div:last-of-type {
  text-align: right;
}
.accept-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 0 0px;
}
.accept-panel button {
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  height: 25px !important;
  margin-left: 10px;
  min-width: 133px;
  background: var(--seconday-background);
  color: black;
}
