delivery-delay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: brightness(60%);
}
delivery-delay > div {
  width: 100%;
  height: fit-content;
  max-width: 450px;
  background-color: white;
  color: black;
  border-radius: 4px;
  overflow: hidden;
}
delivery-delay > div h1 {
  background: var(--primary-background);
  color: white;
  height: 40px;
  font-size: 15px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  font-weight: bold;
}
delivery-delay > div input[type=text] {
  text-align: center;
  width: 80px;
}
delivery-delay > div p {
  padding: 5px 15px;
}
delivery-delay > div label {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 15px;
  box-sizing: border-box;
}
delivery-delay > div .buttons {
  padding: 0 15px 15px;
  display: flex;
  margin-top: 20px;
}
delivery-delay > div .buttons button {
  background: var(--primary-background);
  color: White;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  height: 26px;
  cursor: pointer;
  vertical-align: top;
  border-radius: 4px;
  margin-left: 10px;
  border: none;
  flex: 0 0 130px;
}
delivery-delay > div .buttons button:disabled {
  opacity: 0.5;
  cursor: default;
}
delivery-delay > div .buttons button:first-of-type {
  margin-left: auto;
}
delivery-delay > div .buttons button:hover {
  background: var(--primary-background-hover);
}
delivery-delay > div .buttons button[primary] {
  background: var(--seconday-background);
  color: black;
}
delivery-delay > div .buttons button[primary]:hover {
  background: var(--seconday-background-hover);
}
