one-click-order {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 0%, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
  font-size: 14px;
}
one-click-order > div {
  width: 320px;
  background-color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
one-click-order > div header {
  background: var(--gradient);
  color: white;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  position: relative;
}
one-click-order > div header button {
  position: absolute;
  top: 12px;
  right: 10px;
  background: none;
  border: none;
  opacity: 0.7;
  cursor: pointer;
}
one-click-order > div header button svg {
  width: 15px;
  height: 15px;
  fill: white;
}
one-click-order > div header button:hover {
  opacity: 1;
}
one-click-order > div label {
  color: black;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 3px;
}
one-click-order > div input {
  padding: 6px 12px;
  border-radius: 3px;
  font-size: 14px;
}
one-click-order > div form > div {
  padding: 10px;
  display: flex;
  flex-direction: column;
  color: white;
}
one-click-order > div form > button {
  width: 100%;
  padding: 10px;
  background-color: var(--eurowheels-color);
  color: white;
  font-size: 14px;
  border: none;
  margin-top: 10px;
}
one-click-order > div form > button:hover {
  transform: scale(1.1);
}
