order-notification {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 28px;
  justify-content: flex-start;
  overflow: hidden;
  transition: height 0.6s;
}
order-notification [name=hide] {
  display: none;
}
order-notification [name=expand] {
  display: flex;
}
order-notification[open] {
  height: 128px;
}
order-notification[open] [name=expand] {
  display: none;
}
order-notification[open] [name=hide] {
  display: flex;
}
order-notification [name=note] {
  flex: 0 0 21px;
}
order-notification [name=overview] {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4px 0 6px;
  flex: 0 0 28px;
}
order-notification [name=overview] > div {
  display: flex;
}
order-notification [name=overview] > div svg {
  width: 14px;
  margin-right: 3px;
}
order-notification [name=overview] button {
  border: none;
  font-size: 12px;
  background: #e3e3e3;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 64px;
  height: 20px;
}
order-notification [name=overview] button:disabled {
  color: black;
  background: #e3e3e3;
}
order-notification [name=overview] button svg {
  height: 20px;
}
order-notification [name=options] {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 0 0 0px;
}
order-notification [name=options] fieldset {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: stretch;
  border: none;
  padding: 0 0px;
  box-sizing: border-box;
  padding: 0 10px 0 0;
  height: 28px;
}
order-notification [name=options] fieldset label {
  flex: 0 0 104px;
  display: flex;
  align-items: center;
  float: left;
}
order-notification [name=options] fieldset label input {
  margin-right: 5px;
  display: flex;
  align-items: center;
}
order-notification [name=options] fieldset label + label {
  margin-left: -5px;
}
order-notification [name=options] fieldset input[type=text] {
  flex: 1;
}
