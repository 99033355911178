zoomable-image {
  display: block;
  border: 1px solid #ccc;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 3px;
}
zoomable-image[allow-zoom] {
  cursor: zoom-in;
}
div.zoomed {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.1s;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: zoom-out;
}
div.zoomed img {
  transform: scale(0.01);
  transition: all 0.3s;
  max-height: 100%;
  max-width: 100%;
}
div.zoomed[open] {
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.3);
}
div.zoomed[open] img {
  transform: scale(1);
}
