buy-tpms-button button {
  background-image: url(/img/ewBtns3.png?2012-12-11);
  background-position: 0px 0px;
  background-repeat: no-repeat;
  padding-top: 3px;
  padding-bottom: 3px;
  width: 129px;
  color: White;
  display: inline-block;
  line-height: 20px;
  cursor: pointer;
  vertical-align: top;
  border: none;
  font-size: 12px;
}
