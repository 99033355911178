user-comment-editor {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 16px 0px #ccc;
  color: black;
  display: flex;
  flex-direction: column;
  height: 86px;
  max-height: 100vh;
  max-width: 100%;
  position: absolute;
  width: 350px;
  z-index: 999;
  margin-top: 25px;
}
user-comment-editor[busy] {
  pointer-events: none;
  filter: brightness(0.8);
}
user-comment-editor::after {
  border-color: transparent transparent white white;
  border-style: solid;
  border-width: 5px;
  box-shadow: -8px 8px 16px 0px #ccc;
  box-sizing: border-box;
  content: "";
  height: 0;
  left: 10%;
  position: absolute;
  top: 0;
  transform-origin: 0 0;
  transform: rotate(135deg);
  width: 0;
}
user-comment-editor header {
  height: 40px;
  font-size: 15px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
user-comment-editor input[type="text"] {
  width: 100%;
  height: 100%;
  border: none;
  text-align: left;
  padding: 15px;
  box-sizing: border-box;
}
user-comment-editor input[type="text"]:focus {
  outline: none;
}
user-comment-editor p {
  padding: 5px 15px;
}
user-comment-editor label {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 15px;
  box-sizing: border-box;
}
user-comment-editor [name="buttons"] {
  display: flex;
  margin-top: auto;
  background: var(--primary-background);
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  flex: 0 0 36px;
}
user-comment-editor [name="buttons"] button {
  background: none;
  color: White;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  height: 100%;
  cursor: pointer;
  vertical-align: top;
  border-radius: 0;
  border: none;
  flex: 0 0 80px;
  fill: white;
  display: flex;
  justify-content: center;
  border-left: 1px solid #383838;
  align-items: center;
}
user-comment-editor [name="buttons"] button:disabled {
  opacity: 0.5;
  cursor: default;
}
user-comment-editor [name="buttons"] button:first-of-type {
  margin-left: auto;
}
user-comment-editor [name="buttons"] button:hover {
  background: var(--primary-background-hover);
}
user-comment-editor [name="buttons"] button[primary] {
  background: var(--seconday-background);
  color: black;
}
user-comment-editor [name="buttons"] button[primary]:hover {
  background: var(--seconday-background-hover);
}
