user-comment {
  display: none;
}
user-comment[enabled] {
  background: #e3e3e3;
  border-radius: 3px;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 5px;
  margin-left: 160px;
  margin-top: 5px;
  max-width: calc(100% - 160px);
  padding: 5px 10px;
  width: fit-content;
}
user-comment[comment] > [name="buttons"] {
  margin: 0 0 0 7px;
}
user-comment > [name="buttons"] {
  display: flex;
  width: 100%;
  flex: 0;
  margin: 0;
}
user-comment > [name="buttons"] button {
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  padding: 0;
  text-decoration: none;
  white-space: nowrap;
}
user-comment > [name="buttons"] button[name=add] svg {
  margin-right: 5px;
}
user-comment > [name="buttons"] button svg {
  width: 14px;
}
