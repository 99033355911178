accessory-stock {
  font-size: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
accessory-stock img {
  width: 16px;
  height: 11px;
  margin-right: 5px;
}
