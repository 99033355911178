tyre-display {
  display: flex;
  width: 458px;
  float: left;
  padding-left: 2px;
  margin-top: -5px;
  padding-bottom: 5px;
}
tyre-display .details {
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 4px;
}
tyre-display .details > div:last-of-type {
  margin-top: auto;
  font-size: 10px;
}
tyre-display energy-label {
  flex: 0 0 100px;
  width: 130px;
  align-self: flex-end;
}
tyre-display energy-label .reveal {
  margin-left: 90px !important;
}
tyre-display .overview {
  margin-left: auto;
  flex: 0 0 70px;
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
tyre-display .overview > div:first-of-type {
  font-size: 11px;
  font-weight: bold;
}
tyre-display .overview .badges:first-of-type {
  font-size: 10px;
  margin-bottom: 5px;
}
tyre-display .overview .badges > div {
  border-radius: 5px;
  display: none;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #d8d8d8 0%, #acacac 100%);
  height: 17px;
  font-size: 11px;
  width: 60px;
  margin-top: 4px;
}
tyre-display[offer] .overview .badges div[name="offer"] {
  display: flex;
}
tyre-display[bestseller] .overview .badges div[name="bestseller"] {
  display: flex;
}
tyre-display[news] .overview .badges div[name="news"] {
  display: flex;
}
