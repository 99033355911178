:root {
  --eurowheels-color: #a61e1e;
  --gradient: linear-gradient(to bottom, #767676 0%, #474747 50%, #2d2d2d 100%);
  --highlight-gradient: linear-gradient(to bottom, hsl(37, 97%, 74%) 0%, hsl(30, 84%, 58%) 100%);
}
html[responsive] {
  display: flex;
  justify-content: center;
  height: 100%;
}
html[responsive] * {
  box-sizing: border-box;
}
html[responsive] body {
  background: url(/img/gray-jean.png);
  max-width: 980px;
  width: 100%;
  height: 99%;
}
html:not([logged-in]) .sif08a {
  visibility: hidden;
}
html:not([logged-in]) .stockinfo {
  visibility: hidden;
}
html:not([logged-in]) .saTop .sa03 {
  visibility: hidden;
}
.reveal-modal img {
  max-width: 100%;
  max-height: 100%;
}
