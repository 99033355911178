product-control {
  width: 100%;
  display: flex;
  height: 42px;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #bababa;
}
product-control .image {
  flex: 0 0 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
product-control .image img {
  max-height: 36px;
}
product-control .item-id,
product-control .price {
  flex: 1 1 70px;
}
product-control .price {
  text-align: right;
  padding-right: 10px;
}
product-control .item-no {
  flex: 1 1 145px;
}
product-control .description {
  flex: 1 1 383px;
}
product-control .local-stock {
  flex: 1 1 54px;
}
product-control .buy {
  display: flex;
  align-items: center;
}
product-control .buy buy-button {
  height: 25px;
  margin: 0 10px;
}
