quantity-dropdown {
  width: 46px;
}
quantity-dropdown select {
  width: 100%;
  text-align-last: center;
}
quantity-dropdown[disabled] {
  display: none;
}
