site-header {
  --menu-width: 200px;
}
site-header nav {
  align-items: center;
  background: var(--gradient);
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  font-family: Arial, sans-serif;
  font-size: 12px;
  justify-content: space-between;
  min-height: 50px;
  max-width: 980px;
}
site-header nav svg {
  opacity: 0.9;
}
site-header nav > * {
  flex: 1;
}
site-header nav > * > button,
site-header nav [name="impersonate"],
site-header nav [name="home-link"] {
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex: 0 0 45px;
  justify-content: center;
  margin: 0;
  padding: 0;
}
site-header nav > * > button:hover svg,
site-header nav [name="impersonate"]:hover svg,
site-header nav [name="home-link"]:hover svg {
  opacity: 1;
  transform: scale(1.3);
  transition: all 0.3s;
}
site-header nav .menu-item,
site-header nav .menu-item a {
  padding: 10px 0;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-shadow: 1px 1px 1px #000;
  order: 4;
  display: flex;
  align-items: center;
  position: relative;
}
site-header nav .menu-item svg.dash,
site-header nav .menu-item a svg.dash {
  width: 100%;
  fill: #ffcc05;
  filter: drop-shadow(2px 2px 0px #000);
  margin-left: 2px;
}
site-header nav .menu-item:hover svg.dash,
site-header nav .menu-item a:hover svg.dash {
  display: none;
}
@media screen and (max-width: 800px) {
  site-header nav .menu-item,
  site-header nav .menu-item a {
    padding: 0;
    font-size: 11px;
  }
}
site-header nav .menu-item[active] > a,
site-header nav .menu-item a[active] > a {
  border: 1px solid white;
}
site-header nav .menu-item a,
site-header nav .menu-item a a {
  display: block;
  cursor: pointer;
  padding: 6px 9px;
  transition: all 0.2s;
}
site-header nav .menu-item a[open],
site-header nav .menu-item a a[open] {
  overflow: visible !important;
}
site-header nav .menu-item .sub-sub-menu,
site-header nav .menu-item a .sub-sub-menu,
site-header nav .menu-item .sub-sub-sub-menu,
site-header nav .menu-item a .sub-sub-sub-menu,
site-header nav .menu-item .sub-sub-sub-sub-menu,
site-header nav .menu-item a .sub-sub-sub-sub-menu {
  flex: 0 0 auto;
  max-height: 0;
  position: absolute;
  margin-top: -24px;
  margin-left: 205px;
}
site-header nav .menu-item .sub-menu,
site-header nav .menu-item a .sub-menu,
site-header nav .menu-item > div,
site-header nav .menu-item a > div,
site-header nav .menu-item .sub-sub-menu > div,
site-header nav .menu-item a .sub-sub-menu > div,
site-header nav .menu-item .sub-sub-sub-menu > div,
site-header nav .menu-item a .sub-sub-sub-menu > div,
site-header nav .menu-item .sub-sub-sub-sub-menu > div,
site-header nav .menu-item a .sub-sub-sub-sub-menu > div {
  background: var(--gradient);
  border-radius: 3px;
  align-self: flex-start;
  flex-direction: column;
  left: 0;
  margin-left: calc(((var(--menu-width) / 4) * -1) - 30px);
  margin-top: 35px;
  max-height: 0;
  overflow: hidden;
  position: absolute;
  transition: all 0.3s;
  white-space: nowrap;
  width: var(--menu-width);
  z-index: 3;
  display: flex;
  width: 220px;
}
site-header nav .menu-item .sub-menu[open],
site-header nav .menu-item a .sub-menu[open],
site-header nav .menu-item > div[open],
site-header nav .menu-item a > div[open],
site-header nav .menu-item .sub-sub-menu > div[open],
site-header nav .menu-item a .sub-sub-menu > div[open],
site-header nav .menu-item .sub-sub-sub-menu > div[open],
site-header nav .menu-item a .sub-sub-sub-menu > div[open],
site-header nav .menu-item .sub-sub-sub-sub-menu > div[open],
site-header nav .menu-item a .sub-sub-sub-sub-menu > div[open] {
  overflow: visible;
}
site-header nav .menu-item .sub-menu[open] > div,
site-header nav .menu-item a .sub-menu[open] > div,
site-header nav .menu-item > div[open] > div,
site-header nav .menu-item a > div[open] > div,
site-header nav .menu-item .sub-sub-menu > div[open] > div,
site-header nav .menu-item a .sub-sub-menu > div[open] > div,
site-header nav .menu-item .sub-sub-sub-menu > div[open] > div,
site-header nav .menu-item a .sub-sub-sub-menu > div[open] > div,
site-header nav .menu-item .sub-sub-sub-sub-menu > div[open] > div,
site-header nav .menu-item a .sub-sub-sub-sub-menu > div[open] > div {
  display: initial;
}
site-header nav .menu-item .sub-menu a,
site-header nav .menu-item a .sub-menu a,
site-header nav .menu-item > div a,
site-header nav .menu-item a > div a,
site-header nav .menu-item .sub-sub-menu > div a,
site-header nav .menu-item a .sub-sub-menu > div a,
site-header nav .menu-item .sub-sub-sub-menu > div a,
site-header nav .menu-item a .sub-sub-sub-menu > div a,
site-header nav .menu-item .sub-sub-sub-sub-menu > div a,
site-header nav .menu-item a .sub-sub-sub-sub-menu > div a {
  overflow: hidden;
}
site-header nav .menu-item .sub-menu a > div,
site-header nav .menu-item a .sub-menu a > div,
site-header nav .menu-item > div a > div,
site-header nav .menu-item a > div a > div,
site-header nav .menu-item .sub-sub-menu > div a > div,
site-header nav .menu-item a .sub-sub-menu > div a > div,
site-header nav .menu-item .sub-sub-sub-menu > div a > div,
site-header nav .menu-item a .sub-sub-sub-menu > div a > div,
site-header nav .menu-item .sub-sub-sub-sub-menu > div a > div,
site-header nav .menu-item a .sub-sub-sub-sub-menu > div a > div {
  display: none;
}
site-header nav .menu-item .sub-menu a:first-of-type,
site-header nav .menu-item a .sub-menu a:first-of-type,
site-header nav .menu-item > div a:first-of-type,
site-header nav .menu-item a > div a:first-of-type,
site-header nav .menu-item .sub-sub-menu > div a:first-of-type,
site-header nav .menu-item a .sub-sub-menu > div a:first-of-type,
site-header nav .menu-item .sub-sub-sub-menu > div a:first-of-type,
site-header nav .menu-item a .sub-sub-sub-menu > div a:first-of-type,
site-header nav .menu-item .sub-sub-sub-sub-menu > div a:first-of-type,
site-header nav .menu-item a .sub-sub-sub-sub-menu > div a:first-of-type {
  margin-top: 5px;
}
site-header nav .menu-item .sub-menu a:last-of-type,
site-header nav .menu-item a .sub-menu a:last-of-type,
site-header nav .menu-item > div a:last-of-type,
site-header nav .menu-item a > div a:last-of-type,
site-header nav .menu-item .sub-sub-menu > div a:last-of-type,
site-header nav .menu-item a .sub-sub-menu > div a:last-of-type,
site-header nav .menu-item .sub-sub-sub-menu > div a:last-of-type,
site-header nav .menu-item a .sub-sub-sub-menu > div a:last-of-type,
site-header nav .menu-item .sub-sub-sub-sub-menu > div a:last-of-type,
site-header nav .menu-item a .sub-sub-sub-sub-menu > div a:last-of-type {
  margin-bottom: 5px;
}
site-header nav .menu-item .sub-menu a:hover,
site-header nav .menu-item a .sub-menu a:hover,
site-header nav .menu-item > div a:hover,
site-header nav .menu-item a > div a:hover,
site-header nav .menu-item .sub-sub-menu > div a:hover,
site-header nav .menu-item a .sub-sub-menu > div a:hover,
site-header nav .menu-item .sub-sub-sub-menu > div a:hover,
site-header nav .menu-item a .sub-sub-sub-menu > div a:hover,
site-header nav .menu-item .sub-sub-sub-sub-menu > div a:hover,
site-header nav .menu-item a .sub-sub-sub-sub-menu > div a:hover {
  background-color: var(--eurowheels-color);
  transform: scale(1.06);
  overflow: visible;
}
site-header nav .menu-item .sub-menu a:hover > div,
site-header nav .menu-item a .sub-menu a:hover > div,
site-header nav .menu-item > div a:hover > div,
site-header nav .menu-item a > div a:hover > div,
site-header nav .menu-item .sub-sub-menu > div a:hover > div,
site-header nav .menu-item a .sub-sub-menu > div a:hover > div,
site-header nav .menu-item .sub-sub-sub-menu > div a:hover > div,
site-header nav .menu-item a .sub-sub-sub-menu > div a:hover > div,
site-header nav .menu-item .sub-sub-sub-sub-menu > div a:hover > div,
site-header nav .menu-item a .sub-sub-sub-sub-menu > div a:hover > div {
  display: initial;
}
site-header nav .menu-item .sub-menu[open],
site-header nav .menu-item a .sub-menu[open],
site-header nav .menu-item > div[open],
site-header nav .menu-item a > div[open],
site-header nav .menu-item .sub-sub-menu > div[open],
site-header nav .menu-item a .sub-sub-menu > div[open],
site-header nav .menu-item .sub-sub-sub-menu > div[open],
site-header nav .menu-item a .sub-sub-sub-menu > div[open],
site-header nav .menu-item .sub-sub-sub-sub-menu > div[open],
site-header nav .menu-item a .sub-sub-sub-sub-menu > div[open] {
  overflow: visible !important;
  display: block !important;
}
site-header nav .menu-item .sub-menu[open] .sub-sub-menu,
site-header nav .menu-item a .sub-menu[open] .sub-sub-menu,
site-header nav .menu-item > div[open] .sub-sub-menu,
site-header nav .menu-item a > div[open] .sub-sub-menu,
site-header nav .menu-item .sub-sub-menu > div[open] .sub-sub-menu,
site-header nav .menu-item a .sub-sub-menu > div[open] .sub-sub-menu,
site-header nav .menu-item .sub-sub-sub-menu > div[open] .sub-sub-menu,
site-header nav .menu-item a .sub-sub-sub-menu > div[open] .sub-sub-menu,
site-header nav .menu-item .sub-sub-sub-sub-menu > div[open] .sub-sub-menu,
site-header nav .menu-item a .sub-sub-sub-sub-menu > div[open] .sub-sub-menu {
  max-height: 600px !important;
}
site-header nav .menu-item .sub-menu[open] > div,
site-header nav .menu-item a .sub-menu[open] > div,
site-header nav .menu-item > div[open] > div,
site-header nav .menu-item a > div[open] > div,
site-header nav .menu-item .sub-sub-menu > div[open] > div,
site-header nav .menu-item a .sub-sub-menu > div[open] > div,
site-header nav .menu-item .sub-sub-sub-menu > div[open] > div,
site-header nav .menu-item a .sub-sub-sub-menu > div[open] > div,
site-header nav .menu-item .sub-sub-sub-sub-menu > div[open] > div,
site-header nav .menu-item a .sub-sub-sub-sub-menu > div[open] > div {
  display: block !important;
}
site-header nav .menu-item .sub-menu .close,
site-header nav .menu-item a .sub-menu .close,
site-header nav .menu-item > div .close,
site-header nav .menu-item a > div .close,
site-header nav .menu-item .sub-sub-menu > div .close,
site-header nav .menu-item a .sub-sub-menu > div .close,
site-header nav .menu-item .sub-sub-sub-menu > div .close,
site-header nav .menu-item a .sub-sub-sub-menu > div .close,
site-header nav .menu-item .sub-sub-sub-sub-menu > div .close,
site-header nav .menu-item a .sub-sub-sub-sub-menu > div .close {
  position: absolute;
  display: none;
  padding: 20px;
  border-radius: 50%;
  top: 10px;
  right: 10px;
  z-index: 1;
  background: none;
  border: none;
}
site-header nav .menu-item .sub-menu .close svg,
site-header nav .menu-item a .sub-menu .close svg,
site-header nav .menu-item > div .close svg,
site-header nav .menu-item a > div .close svg,
site-header nav .menu-item .sub-sub-menu > div .close svg,
site-header nav .menu-item a .sub-sub-menu > div .close svg,
site-header nav .menu-item .sub-sub-sub-menu > div .close svg,
site-header nav .menu-item a .sub-sub-sub-menu > div .close svg,
site-header nav .menu-item .sub-sub-sub-sub-menu > div .close svg,
site-header nav .menu-item a .sub-sub-sub-sub-menu > div .close svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}
@media screen and (max-width: 800px) {
  site-header nav .menu-item .sub-menu .close,
  site-header nav .menu-item a .sub-menu .close,
  site-header nav .menu-item > div .close,
  site-header nav .menu-item a > div .close,
  site-header nav .menu-item .sub-sub-menu > div .close,
  site-header nav .menu-item a .sub-sub-menu > div .close,
  site-header nav .menu-item .sub-sub-sub-menu > div .close,
  site-header nav .menu-item a .sub-sub-sub-menu > div .close,
  site-header nav .menu-item .sub-sub-sub-sub-menu > div .close,
  site-header nav .menu-item a .sub-sub-sub-sub-menu > div .close {
    display: block;
  }
}
@media screen and (max-width: 800px) {
  site-header nav .menu-item .sub-menu,
  site-header nav .menu-item a .sub-menu,
  site-header nav .menu-item > div,
  site-header nav .menu-item a > div,
  site-header nav .menu-item .sub-sub-menu > div,
  site-header nav .menu-item a .sub-sub-menu > div,
  site-header nav .menu-item .sub-sub-sub-menu > div,
  site-header nav .menu-item a .sub-sub-sub-menu > div,
  site-header nav .menu-item .sub-sub-sub-sub-menu > div,
  site-header nav .menu-item a .sub-sub-sub-sub-menu > div {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    margin: 0;
    border-radius: 0;
    width: 100%;
  }
  site-header nav .menu-item .sub-menu a,
  site-header nav .menu-item a .sub-menu a,
  site-header nav .menu-item > div a,
  site-header nav .menu-item a > div a,
  site-header nav .menu-item .sub-sub-menu > div a,
  site-header nav .menu-item a .sub-sub-menu > div a,
  site-header nav .menu-item .sub-sub-sub-menu > div a,
  site-header nav .menu-item a .sub-sub-sub-menu > div a,
  site-header nav .menu-item .sub-sub-sub-sub-menu > div a,
  site-header nav .menu-item a .sub-sub-sub-sub-menu > div a {
    padding: 12px;
  }
}
site-header nav .menu-item:hover > a,
site-header nav .menu-item a:hover > a {
  transform: scale(1.3);
  transition: all 0.3s;
}
@media screen and (min-width: 800px) {
  site-header nav .menu-item[open] .sub-menu,
  site-header nav .menu-item a[open] .sub-menu {
    max-height: 600px;
  }
}
site-header nav .menu-item[open] > .sub-menu,
site-header nav .menu-item a[open] > .sub-menu,
site-header nav .menu-item[open] > .sub-sub-menu,
site-header nav .menu-item a[open] > .sub-sub-menu,
site-header nav .menu-item[open] > .sub-sub-sub-menu,
site-header nav .menu-item a[open] > .sub-sub-sub-menu,
site-header nav .menu-item[open] > .sub-sub-sub-sub-menu,
site-header nav .menu-item a[open] > .sub-sub-sub-sub-menu {
  max-height: 600px;
}
@media screen and (max-width: 800px) {
  site-header nav .menu-item[open] > .sub-menu,
  site-header nav .menu-item a[open] > .sub-menu,
  site-header nav .menu-item[open] > .sub-sub-menu,
  site-header nav .menu-item a[open] > .sub-sub-menu,
  site-header nav .menu-item[open] > .sub-sub-sub-menu,
  site-header nav .menu-item a[open] > .sub-sub-sub-menu,
  site-header nav .menu-item[open] > .sub-sub-sub-sub-menu,
  site-header nav .menu-item a[open] > .sub-sub-sub-sub-menu {
    max-height: 100%;
  }
}
site-header nav [name="logo"] {
  flex: 0 0 160px;
  padding: 4px 8px;
  order: 1;
}
site-header nav [name="logo"] svg {
  height: 40px;
}
@media screen and (max-width: 600px) {
  site-header nav [name="logo"] {
    flex: 0 0 45px;
    overflow: hidden;
  }
  site-header nav [name="logo"] svg {
    height: 36px;
    margin-top: 3px;
  }
}
site-header nav [name=menu-panel] {
  order: 3;
  display: flex;
  justify-content: space-around;
}
@media screen and (max-width: 800px) {
  site-header nav [name=menu-panel] {
    order: 10;
    flex: 0 0 100%;
  }
}
site-header nav [name=button-panel] {
  display: flex;
  flex: 0 190px;
  justify-content: space-evenly;
  order: 5;
}
site-header nav [name=button-panel] button {
  height: 45px;
}
@media screen and (max-width: 800px) {
  site-header nav [name=button-panel] {
    margin-left: auto;
    order: 3;
  }
}
site-header nav [name=button-panel] [name="impersonation"] {
  position: relative;
}
site-header nav [name=button-panel] [name="impersonation"] svg {
  width: 23px;
}
site-header nav [name=button-panel] [name="contact"] svg {
  width: 27px;
}
site-header nav [name=button-panel] [name="basket"] {
  padding-right: 10px;
  position: relative;
}
site-header nav [name=button-panel] [name="basket"] svg {
  height: 26px;
}
site-header nav [name=button-panel] [name="basket"] .count {
  background-color: var(--eurowheels-color);
  border-radius: 14px;
  color: white;
  display: flex;
  font-family: -apple-system-apple-system, BlinkMacSystemFont, 'Open sans', sans-serif;
  font-size: 10px;
  line-height: 10px;
  left: 21px;
  top: 7px;
  padding: 4px 6px;
  position: absolute;
  width: auto;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  z-index: 1;
}
site-header nav [name=button-panel] [name="basket"] .count:empty {
  opacity: 0;
}
site-header nav [name=button-panel] [name="tyre-calculator"] svg {
  height: 27px;
}
site-header nav [name="home-link"] {
  flex: 0 0 44px;
  order: 2;
}
site-header nav [name="home-link"] svg {
  height: 22px;
}
.oe-logo {
  float: left;
  height: 47px;
  vertical-align: middle;
  line-height: 47px;
  color: white;
  text-decoration: none;
  font-size: 20px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.75);
  margin: 0 0 0 20px;
}
.ewTopMenu svg {
  position: absolute;
  width: 62px;
  fill: #00c3fd;
  filter: drop-shadow(2px 2px 0px #000);
  top: 30px;
  left: 6px;
}
.ewTopMenu[data-id="4270"] svg {
  fill: #ffcc05;
}
.ewTopMenu:hover svg {
  display: none;
}
