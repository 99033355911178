video-player {
  display: flex;
  justify-content: center;
  align-items: center;
}
video-player .video-js {
  display: flex;
  justify-content: center;
  align-items: center;
}
