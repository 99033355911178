basket-upsale {
  background-color: #f3f3f3;
  border-radius: 4px;
  border: 1px solid #aaa;
  box-sizing: border-box;
  display: block;
  font-family: sans-serif;
  margin: 0 10px 12px !important;
  padding: 10px 20px 20px;
  width: calc(100% - 22px);
}
basket-upsale[busy] {
  pointer-events: none;
  opacity: 0.6;
}
basket-upsale[hidden] {
  opacity: 0;
  max-height: 0;
  transition: all 0.5s;
  overflow: hidden;
  padding: 0;
  margin: 0 !important;
}
basket-upsale header {
  display: flex;
  justify-content: space-between;
}
basket-upsale header div {
  font-weight: 600;
  font-size: 13px;
  padding: 0 0 10px;
  color: #6b6b6b;
  line-height: 1.8;
}
basket-upsale header div:last-of-type {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
basket-upsale button {
  background: var(--primary-background);
  border-radius: 4px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  height: 25px !important;
  margin-left: 10px;
  min-width: 133px;
}
basket-upsale button:hover {
  background: var(--primary-background-hover);
}
basket-upsale > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 13px;
  font-size: 13px;
}
basket-upsale > div:first-of-type {
  background: linear-gradient(90deg, #ffffffab, transparent);
  padding: 0 0 0 6px;
}
basket-upsale > div:first-of-type .price {
  margin-left: auto;
}
basket-upsale .tip {
  font-weight: 400;
  font-size: 12px;
  color: #6b6b6b;
  margin-top: 20px;
  line-height: 18px;
}
