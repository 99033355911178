product-collection {
  width: 100%;
  flex-direction: column;
  display: flex;
}
product-collection > section {
  border-top: 1px solid #bababa;
  border-left: 1px solid #bababa;
  border-radius: 3px;
  overflow: hidden;
  margin-top: 10px;
  transition: max-height 0.5s ease-n;
  max-height: 4000px;
  background: white;
}
product-collection > section:last-of-type {
  margin-bottom: 10px;
}
product-collection > section[collapsed] {
  max-height: 30px;
  transition: max-height 0.5s ease-out;
}
product-collection > section > header {
  background: var(--gradient);
  color: white;
  font-weight: bold;
  height: 30px;
  display: flex;
  align-items: center;
  font-family: sans-serif;
  text-indent: 10px;
  font-size: 12px;
  cursor: pointer;
}
product-collection > section > div {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(163px, 1fr));
}
