site-bar {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  margin-top: 5px;
  width: 100%;
}
site-bar [name="frame"] {
  align-items: center;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #ccc;
  display: flex;
  min-height: 30px;
  justify-content: stretch;
  width: 100%;
}
site-bar button {
  align-items: center;
  background: var(--gradient);
  border-radius: 5px;
  color: White;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  height: 26px;
  justify-content: center;
  margin: 2px;
  text-decoration: none;
}
site-bar [name="light-paper"] {
  flex: 1;
  font-size: 13px;
  font-weight: bold;
  padding: 0px 10px;
  transition: all 0.7s;
}
site-bar [name="light-paper"] a {
  color: black;
}
