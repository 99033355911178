[name="supplier-service"] {
  padding: 1px;
}
[name="supplier-service"] iframe {
  background-color: white;
  border-radius: 3px;
  border: none;
  box-shadow: 0 0 1px #5f5e5e;
  height: 740px;
  width: 100%;
}
[name="supplier-service"] .button-panel {
  height: 40px;
  display: flex;
  align-items: center;
}
[name="supplier-service"] .button-panel a {
  text-transform: uppercase;
  text-decoration: none;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
}
